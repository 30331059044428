import types from '../mutation-types';
import ModelTypeService from 'src/services/modelTypes';
import {
  createModelFiles,
  createNewModelType,
  deleteModelFiles,
  updateModelFiles,
} from 'src/utils/modelTypes';
import { compareTwoArrays } from 'src/components/shared/Helpers/arrayHelper';

const state = () => ({
  modelTypes: [],
  isFetchingModelTypes: false,
  showAddModelTypeModal: false,
  isAddingModelType: false,
  modelTypeToBeEdited: null,
});

const getters = {
  modelTypes: (state) => state.modelTypes,
  isFetchingModelTypes: (state) => state.isFetchingModelTypes,
  showAddModelTypeModal: (state) => state.showAddModelTypeModal,
  isAddingModelType: (state) => state.isAddingModelType,
  modelTypeToBeEdited: (state) => state.modelTypeToBeEdited,
};

const actions = {
  setShowAddModelTypeModal({ commit }, payload) {
    commit(types.SET_SHOW_ADD_MODEL_TYPE_MODAL, payload);
  },

  async fetchModelTypes({ commit }) {
    commit(types.SET_IS_FETCHING_MODEL_TYPES, true);
    const [error, data] = await ModelTypeService.fetchModelTypes(false);
    commit(types.SET_IS_FETCHING_MODEL_TYPES, false);
    if (error) {
      console.log(error);
      return;
    }

    commit(types.SET_MODEL_TYPES, data);
  },

  addNewModelTypeAndFiles({ commit, dispatch }, payload) {
    return new Promise(async (resolve) => {
      commit(types.SET_IS_ADDING_MODEL_TYPE, true);
      const { modelTypePayload } = payload;
      const newModelType = await createNewModelType(modelTypePayload);
      if (!newModelType) {
        commit(types.SET_IS_ADDING_MODEL_TYPE, false);
        return;
      }
      const modelFiles = payload.modelFilesPayload.map((f) => ({
        ...f,
        model_type: newModelType.id,
      }));

      await createModelFiles(modelFiles);
      dispatch('fetchModelTypes');
      commit(types.SET_IS_ADDING_MODEL_TYPE, false);
      dispatch('setShowAddModelTypeModal', false);
      resolve();
    });
  },

  handleEditModelType({ commit, dispatch }, modelToBeEdited) {
    commit(types.SET_SHOW_ADD_MODEL_TYPE_MODAL, true);
    dispatch('setModelToBeEdited', modelToBeEdited);
  },

  setModelToBeEdited({ commit }, modelToBeEdited) {
    commit(types.SET_MODEL_TYPE_TO_BE_EDITED, modelToBeEdited);
  },

  updateModelType({ state, commit, dispatch }, payload) {
    return new Promise(async (resolve) => {
      commit(types.SET_IS_ADDING_MODEL_TYPE, true);
      const modelTypeApiCalls = [];
      const { newModelType, newModelFiles } = payload;
      const {
        id: modelTypeId,
        name: prevModelType,
        model_files: prevModelFiles,
      } = state.modelTypeToBeEdited;

      const isModelTypeChanged = prevModelType !== newModelType;

      if (isModelTypeChanged) {
        modelTypeApiCalls.push(
          ModelTypeService.updateModelType(modelTypeId, {
            name: newModelType,
          })
        );
      }

      const { added, removed, modified } = compareTwoArrays(
        prevModelFiles,
        newModelFiles.map(({ isEdit, ...rest }) => ({ ...rest }))
      );

      if (added.length)
        modelTypeApiCalls.push(
          createModelFiles(
            added.map((f) => ({
              ...f,
              model_type: modelTypeId,
            }))
          )
        );

      if (modified.length) modelTypeApiCalls.push(updateModelFiles(modified));

      if (removed.length)
        modelTypeApiCalls.push(deleteModelFiles(removed.map((f) => f.id)));

      await Promise.all(modelTypeApiCalls);
      dispatch('fetchModelTypes');
      commit(types.SET_IS_ADDING_MODEL_TYPE, false);
      dispatch('setShowAddModelTypeModal', false);
      resolve();
    });
  },
};

const mutations = {
  [types.SET_MODEL_TYPES](state, payload) {
    state.modelTypes = payload;
  },
  [types.SET_IS_FETCHING_MODEL_TYPES](state, payload) {
    state.isFetchingModelTypes = payload;
  },
  [types.SET_SHOW_ADD_MODEL_TYPE_MODAL](state, payload) {
    state.showAddModelTypeModal = payload;
  },
  [types.SET_IS_ADDING_MODEL_TYPE](state, payload) {
    state.isAddingModelType = payload;
  },

  [types.SET_MODEL_TYPE_TO_BE_EDITED](state, payload) {
    state.modelTypeToBeEdited = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
