import types from '../mutation-types';

const getters = {
  fcmToken: (state) => state.fcmToken,
};

const actions = {
  async setFCMToken({ commit }, payload) {
    commit(types.SET_FCM_TOKEN, payload);
    localStorage.setItem('fcmToken', payload);
  },
};

const mutations = {
  [types.SET_FCM_TOKEN](state, token) {
    state.fcmToken = token;
  },
};

const state = {
  fcmToken: localStorage.getItem('fcmToken'),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
