import { useToast } from 'vue-toastification';
import types from '../mutation-types';
import BarcodeService from 'src/services/barcode';
import {
  getUpdateAssociateBarcode,
  getBarcodesDictionary,
  getRemoveAssociatedBarcodes,
} from 'src/utils/barcode';

const toast = useToast();

const state = () => ({
  allBarcode: [],
  allBarcodes: {},
  isFetchingBarcodes: false,
  isCreatingBarcode: false,
  isDeletingBarcode: false,
  associateBarcodes: [],
  isFetchingAssociateBarcodes: false,
  isUpsertingAssociateBarcodes: false,
  isRemovingAssociatedBarcode: false,
});

const getters = {
  allBarcode: (state) => state.allBarcode,
  allBarcodes: (state) => state.allBarcodes,
  isFetchingBarcodes: (state) => state.isFetchingBarcodes,
  isCreatingBarcode: (state) => state.isCreatingBarcode,
  isDeletingBarcode: (state) => state.isDeletingBarcode,
  associateBarcodes: (state) => state.associateBarcodes,
  isFetchingAssociateBarcodes: (state) => state.isFetchingAssociateBarcodes,
  isUpsertingAssociateBarcodes: (state) => state.isUpsertingAssociateBarcodes,
  isRemovingAssociatedBarcode: (state) => state.isRemovingAssociatedBarcode,
};

const actions = {
  async fetchAllBarcodes({ commit }) {
    commit(types.SET_IS_FETCHING_BARCODES, true);
    const [error, data] = await BarcodeService.fetchBarcodes();
    commit(types.SET_IS_FETCHING_BARCODES, false);
    if (error) {
      console.log({ error });
      return;
    }

    commit(types.SET_BARCODES, data);
  },
  async fetchAssociateBarcodes({ commit }, taskId) {
    commit(types.SET_IS_FETCHING_ASSOCIATE_BARCODES, true);
    const [error, data] = await BarcodeService.fetchAssociateBarcodes(taskId);
    commit(types.SET_IS_FETCHING_ASSOCIATE_BARCODES, false);
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_ASSOCIATE_BARCODES, data);
  },

  async createBarcode({ state, commit }) {
    const { allBarcode } = state;
    commit(types.SET_CREATING_BARCODES, true);
    const [error, data] = await BarcodeService.createBarcode(false);
    commit(types.SET_CREATING_BARCODES, false);
    if (error) {
      console.log({ error });
      toast.error('Unable to add barcode');
      return false;
    }
    toast.success('Barcode added successfully');
    commit(types.SET_BARCODES, [...allBarcode, data]);
    return true;
  },

  async deleteBarcode({ state, commit }, payload) {
    const { allBarcode } = state;
    commit(types.SET_DELETING_BARCODES, true);
    const [error, data] = await BarcodeService.deleteBarcode(payload, false);
    commit(types.SET_DELETING_BARCODES, false);
    if (error) {
      console.log({ error });
      toast.error('Unable to delete barcode');
      return false;
    }
    toast.success('Barcode deleted successfully');
    allBarcode.splice(
      allBarcode.findIndex((x) => x.id == payload),
      1
    );
    commit(types.SET_BARCODES, allBarcode);

    return true;
  },

  async createAssociateBarcodes({ state, commit }, payload) {
    const { associateBarcodes, allBarcodes } = state;
    commit(types.SET_UPSERTING_ASSOCIATE_BARCODES, true);
    const [error, data] = await BarcodeService.createAssociateBarcodes(
      payload,
      false
    );
    commit(types.SET_UPSERTING_ASSOCIATE_BARCODES, false);
    if (error) {
      console.log({ error });
      return false;
    }
    commit(types.SET_ASSOCIATE_BARCODES, [
      ...associateBarcodes,
      { ...data, barcode: allBarcodes[data.barcode], barcode_id: data.barcode },
    ]);
    return true;
  },

  async updateAssociateBarcodes({ state, commit }, { id, payload }) {
    const { associateBarcodes, allBarcodes } = state;
    commit(types.SET_UPSERTING_ASSOCIATE_BARCODES, true);
    const [error, data] = await BarcodeService.updateAssociateBarcodes(
      id,
      payload,
      false
    );
    commit(types.SET_UPSERTING_ASSOCIATE_BARCODES, false);
    if (error) {
      console.log({ error });
      return false;
    }
    const updatedAssociatedBarcodes = getUpdateAssociateBarcode(
      allBarcodes,
      associateBarcodes,
      data
    );
    commit(types.SET_ASSOCIATE_BARCODES, updatedAssociatedBarcodes);
    return true;
  },

  async removeAssociatedBarcode({ state, commit }, id) {
    const { associateBarcodes } = state;
    commit(types.SET_IS_REMOVING_ASSOCIATED_BARCODE, true);
    const [error, data] = await BarcodeService.removeAssociatedBarcode(
      id,
      false
    );
    commit(types.SET_IS_REMOVING_ASSOCIATED_BARCODE, false);
    if (error) {
      console.log(error);
      toast.error('Error in Removing Barcode');
      return false;
    }
    const updatedAssociatedBarcodes = getRemoveAssociatedBarcodes(
      associateBarcodes,
      id
    );
    commit(types.SET_ASSOCIATE_BARCODES, [...updatedAssociatedBarcodes]);
    return true;
  },
};

const mutations = {
  [types.SET_BARCODES](state, payload) {
    state.allBarcode = payload;
    state.allBarcodes = getBarcodesDictionary(payload);
  },
  [types.SET_CREATING_BARCODES](state, payload) {
    state.isCreatingBarcode = payload;
  },
  [types.SET_IS_FETCHING_BARCODES](state, payload) {
    state.isFetchingBarcodes = payload;
  },
  [types.SET_IS_FETCHING_ASSOCIATE_BARCODES](state, payload) {
    state.isFetchingAssociateBarcodes = payload;
  },
  [types.SET_ASSOCIATE_BARCODES](state, payload) {
    state.associateBarcodes = payload;
  },
  [types.SET_UPSERTING_ASSOCIATE_BARCODES](state, payload) {
    state.isUpsertingAssociateBarcodes = payload;
  },
  [types.SET_IS_REMOVING_ASSOCIATED_BARCODE](state, payload) {
    state.isRemovingAssociatedBarcode = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
