import { useToast } from 'vue-toastification';
import types from '../mutation-types';
import AwsService from 'src/services/awsLambdaToken';

const toast = useToast();

const state = {
  userPoolConfig: {},
  isFetchingConfig: false,
  isCreatingToken: false,
};

const getters = {
  userPoolConfig: (state) => state.userPoolConfig,
  isFetchingConfig: (state) => state.isFetchingConfig,
  isCreatingToken: (state) => state.isCreatingToken,
};

const actions = {
  async fetchUserPoolConfig({ commit }) {
    commit(types.SET_IS_FETCHING_CONFIG, true);
    const [error, data] = await AwsService.fetchUserPoolConfig();
    commit(types.SET_IS_FETCHING_CONFIG, false);
    if (error) {
      console.log({ error });
      return;
    }
    commit(types.SET_USER_POOL_CONFIG, data);
  },

  async createAwsLambdaToken({state, commit},payload) {
    commit(types.SET_CREATING_AWS_TOKEN, true);
    const [error, data] = await AwsService.createAwsLambdaToken(payload,true);
    commit(types.SET_CREATING_AWS_TOKEN, false);
    if (error) {
      console.log({ error });
      toast.error('Unable to create token');
      return false;
    }
    toast.success('Token refreshed successfully');
    commit(types.SET_USER_POOL_CONFIG, data);
    return true;
  },
};

const mutations = {
  [types.SET_USER_POOL_CONFIG](state, payload) {
    state.userPoolConfig = payload;
  },
  [types.SET_CREATING_AWS_TOKEN](state, payload) {
    state.isCreatingToken = payload;
  },
  [types.SET_IS_FETCHING_CONFIG](state, payload) {
    state.isFetchingConfig = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
