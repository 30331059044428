export const localStorageItems = [
  'id',
  'name',
  'role',
  'organization',
  'token',
  'timestamp',
  'hostURL',
  'poc',
  'isPolicyAccepted',
  'twk_625ce4597b967b11798b2ca8',
  'twk_token_625ce4597b967b11798b2ca8',
  'fcmToken',
  'email',
  'sessionEndTime',
  'sessionId',
  'sessionUser',
];
