import ModelTypeService from 'src/services/modelTypes';

export const createNewModelType = (payload) => {
  return new Promise(async (resolve) => {
    const [error, data] = await ModelTypeService.createModelType(
      payload,
      false
    );
    if (error) {
      console.log('Error occurred while creating model type:', error);
      return resolve(null);
    }
    resolve(data);
  });
};

export const createModelFiles = (payload) => {
  return new Promise(async (resolve) => {
    const [error, data] = await ModelTypeService.createModelFiles(
      payload,
      false
    );
    if (error) {
      console.log('Error occurred while creating model files:', error);
      return resolve(null);
    }
    resolve(data);
  });
};

export const updateModelFiles = (payload) => {
  return new Promise(async (resolve) => {
    const [error, data] = await ModelTypeService.updateModelFiles(
      payload,
      false
    );
    if (error) {
      console.log('Error occurred while updating model files:', error);
      return resolve(null);
    }
    resolve(data);
  });
};

export const deleteModelFiles = (modelFileIds) => {
  return new Promise(async (resolve) => {
    const queryParams = { ids: modelFileIds.join(',') };
    const [error, data] = await ModelTypeService.deleteModelFIles(
      false,
      queryParams
    );
    if (error) {
      console.log('Error occurred while updating model files:', error);
      return resolve(null);
    }
    resolve(data);
  });
};
