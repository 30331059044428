import { getToken, isSupported, onMessage } from 'firebase/messaging';
import store from 'src/store';
import FCMService from 'src/services/fcm.js';
import { vapidKey } from 'src/config/fcm-config.js';
import DeviceConfig from 'src/config/device-config';
import firebaseMessaging from 'src/config/firebase-config';

export const getFCMToken = () => {
  return new Promise(async resolve => {
    try {
      const token = await getToken(firebaseMessaging, { vapidKey });
      if (!token)
        console.log(
          'No registration token available. Request permission to generate one.'
        );
      resolve(token);
      store.dispatch('setFCMToken', token);
    } catch (error) {
      console.log('Error occurred in receiving a token', error);
      resolve(null);
    }
  });
};

const subscribeToChannel = async topic => {
  let token = store.state.fcm.fcmToken;
  if (!token) {
    console.log('Token not found!');
    return;
  }
  const payload = {
    topic,
    tokens: [token],
    action: DeviceConfig.subscribe
  };

  const [error] = await FCMService.subscribeToChannel(payload);
  if (error) {
    console.log('Error occurred while subscribing to a channel.');
    return;
  }
};

const unsubscribeToChannel = async topic => {
  let token = store.state.fcm.fcmToken;
  if (!token) {
    console.log('Token not found!');
    return;
  }
  const payload = {
    topic,
    tokens: [token],
    action: DeviceConfig.unsubscribe
  };

  const [error] = await FCMService.subscribeToChannel(payload);
  if (error) {
    console.log('Error occurred while unsubscribing to a channel.');
    return;
  }
};

export const setupFCM = async () => {
  const isSupportedBrowser = await isSupported();
  console.log({ isSupportedBrowser });
  if (!isSupportedBrowser) return;
  // debugger
  const organization = store.state.auth.organization;
  let fcmToken = store.state.fcm.fcmToken;
  if (!fcmToken) {
    await getFCMToken();
    const topic = `${organization}-${DeviceConfig.devices}`;
    subscribeToChannel(topic); // subscribing to devices topic
  }
};

//{org_name}-seg-and-track-response

export const setupFCMforSegmentTrack = async () => {
  const isSupportedBrowser = await isSupported();
  console.log({ isSupportedBrowser });
  if (!isSupportedBrowser) return;
  // debugger
  const organization = store.state.auth.organization;
  let fcmToken = store.state.fcm.fcmToken;
  if (!fcmToken) {
    await getFCMToken();
    const topic = `${organization}-seg-and-track-response`;
    await subscribeToChannel(topic); // subscribing to devices topic
  }
};

export const teardownFCM = () => {
  const organization = store.state.auth.organization;
  const topic = `${organization}-${DeviceConfig.devices}`;
  unsubscribeToChannel(topic); // subscribing to devices topic
};

export const teardownFCMSegmentTrack = () => {
  const organization = store.state.auth.organization;
  const topic = `${organization}-seg-and-track-response`;
  unsubscribeToChannel(topic); // subscribing to devices topic
};

export const messaging = firebaseMessaging;
export const handleMessage = onMessage;
