import apiHelper from './index';

const fetchVirtualTasks = async (
  parentTaskId,
  spinner = true,
  queryParams = {}
) => {
  return await apiHelper.get(
    `organization/task/virtual_tasks/${parentTaskId}/`,
    spinner,
    queryParams
  );
};

export default {
  fetchVirtualTasks,
};
