export const getDeviceSerialNumToDisplayNameMap = (devices) => {
  return devices.reduce((res, d) => {
    res[d.Serial_number] = d.display_name || d.Serial_number;
    return res;
  }, {});
};

export const getDeviceDisplayNameToSerialNumMap = (devices) => {
  return devices.reduce((res, d) => {
    res[`${d.display_name || d.Serial_number}`] = d.Serial_number;
    return res;
  }, {});
};

export const getDeviceIdToNameMap = (devices) => {
  return devices.reduce((res, d) => {
    res[d.Device] = d.display_name || d.Serial_number;
    return res;
  }, {});
};
export const getDeviceIdToSerialNumMap = (devices) => {
  return devices.reduce((res, d) => {
    res[d.Device] = d.Serial_number;
    return res;
  }, {});
};

export const getSortedDevice = (devices) => {
  return [...devices]
    .sort((a, b) => b.starred - a.starred)
    ?.map((d) => ({
      value: d.display_name || d.Serial_number,
      label: (d.starred ? '⭐ ' : '') + (d.display_name || d.Serial_number),
    }));
};

export const getSortedDeviceForTag = (devices) => {
  let sortedDevices = [...devices]
    .sort((a, b) => b.starred - a.starred)
    .filter((d) => d.Device)
    ?.map((d) => {
      return {
        value: d.Device ? d.Device : d.id,
        label: (d.starred ? '⭐ ' : '') + (d.display_name || d.Serial_number),
      };
    });
  return sortedDevices;
};
