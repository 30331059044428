import { useToast } from 'vue-toastification';
import types from '../mutation-types';
import axios from 'axios';
import dateHelper from 'src/components/shared/Helpers/dateHelper';
import ErgonomicsService from 'src/services/ergonomics';
import { getCreateRecordPayload } from 'src/utils/ergonomics';

const toast = useToast();

const state = () => ({
  ergonomicsVideosList: [],
  ergonomicsVideosCount: 0,
  ergonomicsLoading: false,
  isfetchingJson: false,
  selectedVideo: null,
  currentErgonomicsPage: 1,
  rebaFramesData: [],
  rebaAnglesData: [],
  rulaFramesData: [],
  owasFramesData: [],
  loadPercentageJson: null,
  loadTimestampsJson: null,
});

const getters = {
  ergonomicsVideosList: (state) => state.ergonomicsVideosList,
  ergonomicsVideosCount: (state) => state.ergonomicsVideosCount,
  ergonomicsLoading: (state) => state.ergonomicsLoading,
  isFetchingJson: (state) => state.isFetchingJson,
  selectedVideo: (state) => state.selectedVideo,
  currentErgonomicsPage: (state) => state.currentErgonomicsPage,
  rebaFramesData: (state) => state.rebaFramesData,
  rebaAnglesData: (state) => state.rebaAnglesData,
  rulaFramesData: (state) => state.rulaFramesData,
  owasFramesData: (state) => state.owasFramesData,
  loadPercentageJson: (state) => state.loadPercentageJson,
  loadTimestampsJson: (state) => state.loadTimestampsJson,
};

const actions = {
  setCurrentPage({ commit }, page) {
    page = page <= 0 ? 1 : page;
    commit(types.SET_CURRENT_ERGONOMICS_RECORD_PAGE, page);
  },

  updateVideoList({ commit, state }, record) {
    const { ergonomicsVideosList } = state;
    const temp = [...ergonomicsVideosList];
    const index = temp.findIndex((el) => el.id === record.id);
    temp[index] = record;
    commit(types.SET_ERGONOMICS_VIDEOS_LIST, temp);
  },

  setSelectedVideo({ commit }, video) {
    commit(types.SET_SELECTED_ERGONOMICS_RECORD, video);
  },

  setJsonDataLoading({ commit }, payload) {
    commit(types.SET_IS_FETCHING_JSON, payload);
  },

  resetVideoData({ commit, dispatch }) {
    dispatch('setSelectedVideo', null);
    commit(types.SET_REBA_ANGLES_DATA, []);
    commit(types.SET_REBA_FRAMES_DATA, []);
  },

  async fetchErgonomicsVideoRecords({ commit, state }) {
    commit(types.SET_ERGONOMICS_LOADING, true);
    const { currentErgonomicsPage } = state;
    const [error, data] = await ErgonomicsService.getErgonomicsRecords(
      { page: currentErgonomicsPage },
      false
    );
    commit(types.SET_ERGONOMICS_LOADING, false);
    if (error) {
      toast.error('Error in getting videos!');
      return;
    }
    const videos = data.results.map((video) => ({
      ...video,
      start_time: dateHelper.formatDate(video.start_time),
      end_time: video.end_time ? dateHelper.formatDate(video.end_time) : '',
    }));
    commit(types.SET_ERGONOMICS_VIDEOS_LIST, videos);
    commit(types.SET_ERGONOMICS_VIDEOS_COUNT, data.count);
  },

  async deleteErgonomicsRecord({ commit, state, dispatch }, recordId) {
    commit(types.SET_ERGONOMICS_LOADING, true);
    const { currentErgonomicsPage: page, ergonomicsVideosList: list } = state;
    const [error] = await ErgonomicsService.deleteErgonomicsRecord(
      recordId,
      false
    );
    commit(types.SET_ERGONOMICS_LOADING, false);
    if (error) {
      this.toast.error('Unable to delete record!');
      return;
    }
    list.length === 1
      ? dispatch('setCurrentPage', page - 1)
      : dispatch('fetchErgonomicsVideoRecords');
  },

  async createErgonomicsRecords({ commit, dispatch }, uploadedVideos) {
    commit(types.SET_ERGONOMICS_LOADING, true);
    const responses = uploadedVideos.map((file) => {
      const payload = getCreateRecordPayload(file);
      return ErgonomicsService.createErgonomicsRecord(payload);
    });
    await Promise.all([responses]);
    commit(types.SET_ERGONOMICS_LOADING, false);
    dispatch('fetchErgonomicsVideoRecords');
  },

  async getRebaAnglesData({ commit, state }) {
    const { selectedVideo } = state;
    try {
      if (selectedVideo?.reba_angles_url) {
        const { data: anglesData } = await axios.get(
          selectedVideo.reba_angles_url
        );
        commit(types.SET_REBA_ANGLES_DATA, anglesData);
      }
    } catch (error) {
      commit(types.SET_REBA_ANGLES_DATA, []);
    }
  },

  async getRebaFramesData({ commit, state }) {
    const { selectedVideo } = state;
    try {
      if (selectedVideo?.reba_frames_url) {
        const { data: framesData } = await axios.get(
          selectedVideo.reba_frames_url
        );
        commit(types.SET_REBA_FRAMES_DATA, framesData);
      }
    } catch (error) {
      commit(types.SET_REBA_FRAMES_DATA, []);
    }
  },

  async getRulaFramesData({ commit, state }) {
    const { selectedVideo } = state;
    try {
      if (selectedVideo?.rula_frames_url) {
        const { data: framesData } = await axios.get(
          selectedVideo.rula_frames_url
        );
        commit(types.SET_RULA_FRAMES_DATA, framesData);
      }
    } catch (error) {
      commit(types.SET_RULA_FRAMES_DATA, []);
    }
  },

  async getOwasFramesData({ commit, state }) {
    const { selectedVideo } = state;
    try {
      if (selectedVideo?.owas_frames_url) {
        const { data: framesData } = await axios.get(
          selectedVideo.owas_frames_url
        );
        commit(types.SET_OWAS_FRAMES_DATA, framesData);
      }
    } catch (error) {
      commit(types.SET_OWAS_FRAMES_DATA, []);
    }
  },

  async getLoadPercentageJson({ commit, state }) {
    const { selectedVideo } = state;
    try {
      if (selectedVideo?.load_percentage_json_url) {
        const { data: loadPercentageData } = await axios.get(
          selectedVideo.load_percentage_json_url
        );
        commit(types.SET_LOAD_PERCENTAGE_DATA, loadPercentageData);
      }
    } catch (error) {
      commit(types.SET_LOAD_PERCENTAGE_DATA, null);
    }
  },

  async getLoadTimestampsJson({ commit, state }) {
    const { selectedVideo } = state;
    try {
      if (selectedVideo?.load_timestamps_json_url) {
        const { data: loadTimestampsData } = await axios.get(
          selectedVideo.load_timestamps_json_url
        );
        commit(types.SET_LOAD_TIMESTAMPS_DATA, loadTimestampsData);
      }
    } catch (error) {
      commit(types.SET_LOAD_TIMESTAMPS_DATA, null);
    }
  },
};

const mutations = {
  [types.SET_ERGONOMICS_VIDEOS_LIST](state, payload) {
    state.ergonomicsVideosList = payload;
  },
  [types.SET_ERGONOMICS_VIDEOS_COUNT](state, payload) {
    state.ergonomicsVideosCount = payload;
  },
  [types.SET_ERGONOMICS_LOADING](state, payload) {
    state.ergonomicsLoading = payload;
  },
  [types.SET_SELECTED_ERGONOMICS_RECORD](state, payload) {
    state.selectedVideo = payload;
  },
  [types.SET_CURRENT_ERGONOMICS_RECORD_PAGE](state, payload) {
    state.currentErgonomicsPage = payload;
  },
  [types.SET_IS_FETCHING_JSON](state, payload) {
    state.isFetchingJson = payload;
  },
  [types.SET_REBA_FRAMES_DATA](state, payload) {
    state.rebaFramesData = payload;
  },
  [types.SET_REBA_ANGLES_DATA](state, payload) {
    state.rebaAnglesData = payload;
  },
  [types.SET_RULA_FRAMES_DATA](state, payload) {
    state.rulaFramesData = payload;
  },
  [types.SET_OWAS_FRAMES_DATA](state, payload) {
    state.owasFramesData = payload;
  },
  [types.SET_LOAD_PERCENTAGE_DATA](state, payload) {
    state.loadPercentageJson = payload;
  },
  [types.SET_LOAD_TIMESTAMPS_DATA](state, payload) {
    state.loadTimestampsJson = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
