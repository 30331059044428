import types from '../mutation-types';
import DeviceService from 'src/services/device';
import {
  getDeviceSerialNumToDisplayNameMap,
  getDeviceDisplayNameToSerialNumMap,
  getDeviceIdToNameMap,
  getDeviceIdToSerialNumMap,
} from 'src/utils/device';

const state = {
  taskCropParams: {},
  devices: [],
  activeDevices: [],
  isFetchingDevices: false,
  deviceSerialNumToDisplayNameMap: {},
  deviceDisplayNameToSerialNumMap: {},
  deviceIdToNameMap: {},
  deviceIdToSerialNumMap: {},
};

const getters = {
  taskCropParams: (state) => state.taskCropParams,
  devices: (state) => state.devices,
  activeDevices: (state) => state.activeDevices,
  isFetchingDevices: (state) => state.isFetchingDevices,
  deviceSerialNumToDisplayNameMap: (state) =>
    state.deviceSerialNumToDisplayNameMap,
  deviceDisplayNameToSerialNumMap: (state) =>
    state.deviceDisplayNameToSerialNumMap,
  deviceIdToNameMap: (state) => state.deviceIdToNameMap,
  deviceIdToSerialNumMap: (state) => state.deviceIdToSerialNumMap,
};

const actions = {
  setTaskCropParams({ commit }, payload) {
    commit(types.SET_TASK_CROP_PARAMS, payload);
  },

  async getOrgDevices({ commit }, orgName) {
    commit(types.SET_IS_FETCHING_DEVICES, true);
    const [error, data] = await DeviceService.fetchAllDevicesOfOrg(
      orgName,
      false
    );
    commit(types.SET_IS_FETCHING_DEVICES, false);
    if (error) {
      console.log({ error });
      return;
    }
    const devices = data.filter(
      (device) => device.Type !== 'departmental device'
    );
    commit(types.SET_DEVICES, devices);
    commit(
      types.SET_DEVICE_SERIAL_NUM_TO_DISPLAY_NAME_MAP,
      getDeviceSerialNumToDisplayNameMap(devices)
    );
    commit(
      types.SET_DISPLAY_NAME_TO_DEVICE_SERIAL_NUM_MAP,
      getDeviceDisplayNameToSerialNumMap(devices)
    );
    commit(types.SET_DEVICE_ID_TO_NAME_MAP, getDeviceIdToNameMap(devices));
    commit(
      types.SET_DEVICE_ID_TO_DEVICE_SERIAL_NUM_MAP,
      getDeviceIdToSerialNumMap(devices)
    );
  },
};

const mutations = {
  [types.SET_TASK_CROP_PARAMS](state, payload) {
    state.taskCropParams = payload;
  },
  [types.SET_DEVICES](state, payload) {
    state.devices = payload;
  },
  [types.SET_ACTIVE_DEVICES](state, payload) {
    state.activeDevices = payload;
  },
  [types.SET_IS_FETCHING_DEVICES](state, payload) {
    state.isFetchingDevices = payload;
  },
  [types.SET_DEVICE_SERIAL_NUM_TO_DISPLAY_NAME_MAP](state, payload) {
    state.deviceSerialNumToDisplayNameMap = payload;
  },
  [types.SET_DISPLAY_NAME_TO_DEVICE_SERIAL_NUM_MAP](state, payload) {
    state.deviceDisplayNameToSerialNumMap = payload;
  },
  [types.SET_DEVICE_ID_TO_NAME_MAP](state, payload) {
    state.deviceIdToNameMap = payload;
  },
  [types.SET_DEVICE_ID_TO_DEVICE_SERIAL_NUM_MAP](state, payload) {
    state.deviceIdToSerialNumMap = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
