import types from '../mutation-types';
import httpClient from '../../service/httpClient';
import { useToast } from 'vue-toastification';
import OrganizationService from 'src/services/organization';

const toast = useToast();

const state = {
  organizations: [],
  isLoadingOrganizations: false
};

const getters = {
  organizations: state => state.organizations,
  isLoadingOrganizations: state => state.isLoadingOrganizations
};

const actions = {
  async getOrganizations({ commit }) {
    commit(types.SET_IS_LOADING_ORGANIZATIONS, true);
    const list = await httpClient.get('organization/', false);
    commit(types.SET_IS_LOADING_ORGANIZATIONS, false);
    commit(types.SET_ORGANIZATION_LIST, list);
  },
  async addNewOrg({ commit, dispatch }, payload) {
    const requestData = JSON.stringify(payload);
    const response = await httpClient.post(
      'organization/',
      requestData,
      false,
      false,
      true,
      'Organization added successfully!'
    );
    console.log('add err response ->', response);
    if (response === 'error') return response;
    dispatch('getOrganizations');
  },

  async updateSingleOrg({ commit }, payload) {
    const { id, ...resData } = payload;
    const requestData = JSON.stringify(resData);
    const response = await httpClient.put(
      'organization/',
      id + '/',
      requestData
    );
    return response;
  },
  async deleteSingleOrg({ commit, dispatch }, payload) {
    const response = await httpClient.delete('organization/', payload + '/');
    if (response === 'error') {
      toast.error('Error occured while deleting the organization!');
      return;
    }
    dispatch('getOrganizations');
  },
  async refreshVideos({ commit, dispatch }, payload) {
    const url = `cloud_source_labeling/refresh_urls_for_trainig_videos/?Org_name=${payload}`;
    const response = await httpClient.getData(url);
    return response;
  },
  async switchOrganization({ commit }, payload) {
    const [error, data] = await OrganizationService.switchOrganization(payload);
    if (error) {
      toast.error(error?.response.data.error);
      return false;
    }
    toast.success('User switched successfully');
    return true;
  }
};

const mutations = {
  [types.SET_ORGANIZATION_LIST](state, payload) {
    state.organizations = payload;
  },
  [types.SET_IS_LOADING_ORGANIZATIONS](state, payload) {
    state.isLoadingOrganizations = payload;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
