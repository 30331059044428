import httpClient from '../../service/httpClient';
import types from '../mutation-types';
import { useToast } from 'vue-toastification';

const toast = useToast();

const state = {
  pendingUserList: [],
};

const getters = {
  pendingUserList: (state) => state.pendingUserList,
};

const actions = {
  async getPendingUsersList({ commit }) {
    const response = await httpClient.get(
      'account/users_list?is_admin=false&is_active=false'
    );
    console.log('pending ->', response);
    commit(types.GET_PENDING_USERS, response);
  },
  async approveUser({ commit, dispatch }, payload) {
    console.log('payload -> ', payload);
    const { email, roleId } = payload;
    const approveResponse = await httpClient.put(
      `account/approve_user`,
      '',
      JSON.stringify({ email, roleId }),
      true,
      true,
      true,
      'User approved successfully!'
    );
    if (approveResponse === 'error') {
      toast.error('Error in Approving User');
      return approveResponse;
    }
    dispatch('getPendingUsersList');
    const orgResponse = await httpClient.put(
      'account/',
      'assign_user_org',
      JSON.stringify(payload),
      true,
      true,
      false
    );
    if (orgResponse === 'error') {
      toast.error('Error occured while setting the organisation');
      return orgResponse;
    } else {
      return 'success';
    }
  },
};

const mutations = {
  [types.GET_PENDING_USERS](state, payload) {
    state.pendingUserList = [...payload];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
