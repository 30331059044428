import apiHelper from './index';

const getPreviousReleases = async (currentPage) => {
  return await apiHelper.get(
    `binary_deployment/previous_releases/?page=${currentPage}`
  );
};

const downloadBinaries = async (
  running_environment,
  branch,
  version_number,
  isLatestBranch
) => {
  return await apiHelper.get(
    `binary_deployment/download_binaries/?environment=${running_environment}&branch=${branch}&version=${version_number}&isLatestBranch=${isLatestBranch}`
  );
};

const getLatestDeployment = async (env) => {
  return await apiHelper.get(`binary_deployment/${env}/latest_deployment/`);
};

const getLatestDeployableBranch = async () => {
  return await apiHelper.get(`binary_deployment/get_latest_deployable_branch/`);
};

const deleteBranchFromAWS = async (running_environment, branch, id) => {
  return await apiHelper.remove(
    `binary_deployment/delete_branch/?environment=${running_environment}&branch=${branch}&id=${id}`
  );
};

const moveBranchToDev = async (version, env) => {
  return await apiHelper.patch(`binary_deployment/move_binaries_to_prod/`, {
    version: version,
    environment: env,
  });
};

export default {
  getPreviousReleases,
  downloadBinaries,
  getLatestDeployment,
  getLatestDeployableBranch,
  deleteBranchFromAWS,
  moveBranchToDev,
};
