import axios from 'axios';
import URL from './baseUrl';
import store from '../store';
import {
  getAzureLoginStatus,
  setAzureToken,
  removeAzureToken
} from '../utils/auth';
import { azureRefreshToken, refreshJWTToken } from 'src/store/modules/auth.js';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, request } from 'src/mixins/msalHelper';
import validate from 'validate-azure-ad-token';
import { getAuthConfig, getUploadConfig } from './config';

export function getHostUrl() {
  var hostURL = localStorage.getItem('hostURL');
  if (hostURL == null) {
    localStorage.setItem('hostURL', URL.prodHostUrl);
    hostURL = localStorage.getItem('hostURL');
  }
  return hostURL;
  // return URL.localHostUrl;
}

const baseURL = getHostUrl();
// const baseURL = URL.testHostUrl;
const msalInstance = new PublicClientApplication(msalConfig);

async function validateToken(authData) {
  console.log('Validate Token');
  console.log(authData);
  try {
    const decodedToken = await validate(authData.accessToken, {
      tenantId: authData.account.idTokenClaims.tid,
      audience: '00000003-0000-0000-c000-000000000000',
      applicationId: authData.account.idTokenClaims.aud,
      scopes: authData.scopes
    });
    return true;
  } catch (error) {
    return false;
  }
}

export async function logoutAzureUser(msalInstance) {
  store.dispatch('clearState');
}

export async function RedirectUserPasswordExpired() {
  store.dispatch('userRedirectPasswordExpired');
}

export const axiosInstance = axios.create({
  baseURL
});

let tokenPromise = null;

async function refreshToken() {
  if (!tokenPromise) {
    tokenPromise = refreshJWTToken()
      .then(token => {
        tokenPromise = null; // clear state
        return token; // resolve with the new token
      })
      .catch(error => {
        tokenPromise = null; // clear state in case of error
        return Promise.reject(error);
      });
  }

  return tokenPromise;
}

axiosInstance.interceptors.response.use(
  resp => resp,
  async error => {
    const {
      config,
      response: { status }
    } = error;
    const originalRequest = config;

    if (status === 401) {
      try {
        const token = await refreshToken();
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axiosInstance(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    } else if (status === 303) {
      RedirectUserPasswordExpired();
    } else if (status === 499) {
      logoutAzureUser(msalInstance);
    } else if (status === 498) {
      try {
        removeAzureToken();
        const tokenResponse = await msalInstance.acquireTokenSilent(request);
        const isTokenValid = await validateToken(tokenResponse);
        setAzureToken();

        if (!isTokenValid) {
          throw new Error('Token Invalid');
        }
      } catch (error) {
        logoutAzureUser(msalInstance);
        return;
      }

      azureRefreshToken({ access_token: tokenResponse.accessToken });

      if (getAzureLoginStatus()) {
        return axiosInstance(originalRequest).then(() => {
          location.reload();
        });
      } else {
        logoutAzureUser(msalInstance);
      }
    }

    return Promise.reject(error);
  }
);

async function get(
  url,
  spinner = true,
  queryParams = {},
  config = getAuthConfig()
) {
  if (spinner) store.dispatch('turnOnSpinner');

  try {
    const { data } = await axiosInstance.get(url, {
      params: { ...queryParams },
      ...config
    });
    return [null, data];
  } catch (error) {
    return [error];
  } finally {
    if (spinner) store.dispatch('turnOffSpinner');
  }
}

async function post(url, payload, spinner = true, config = getAuthConfig()) {
  if (spinner) store.dispatch('turnOnSpinner');

  try {
    const { data } = await axiosInstance.post(url, payload, {
      ...config
    });
    return [null, data];
  } catch (error) {
    return [error];
  } finally {
    if (spinner) store.dispatch('turnOffSpinner');
  }
}

async function put(url, payload, spinner = true, config = getAuthConfig()) {
  if (spinner) store.dispatch('turnOnSpinner');

  try {
    const { data } = await axiosInstance.put(url, payload, {
      ...config
    });
    return [null, data];
  } catch (error) {
    return [error];
  } finally {
    if (spinner) store.dispatch('turnOffSpinner');
  }
}

async function patch(url, payload, spinner = true, config = getAuthConfig()) {
  if (spinner) store.dispatch('turnOnSpinner');
  try {
    const { data } = await axiosInstance.patch(url, payload, {
      ...config
    });
    return [null, data];
  } catch (error) {
    return [error];
  } finally {
    if (spinner) store.dispatch('turnOffSpinner');
  }
}

async function remove(
  url,
  spinner = true,
  queryParams = {},
  config = getAuthConfig()
) {
  if (spinner) store.dispatch('turnOnSpinner');
  console.log({ config });
  try {
    const { data } = await axiosInstance.delete(url, {
      params: { ...queryParams },
      ...config
    });
    return [null, data];
  } catch (error) {
    return [error];
  } finally {
    if (spinner) store.dispatch('turnOffSpinner');
  }
}

async function upload(
  url,
  payload,
  spinner = true,
  queryParams = {},
  config = getUploadConfig()
) {
  if (spinner) store.dispatch('turnOnSpinner');
  console.log({ config });
  try {
    const { data } = await axiosInstance.post(url, payload, {
      params: { ...queryParams },
      ...config
    });
    return [null, data];
  } catch (error) {
    return [error];
  } finally {
    if (spinner) store.dispatch('turnOffSpinner');
  }
}

export default { get, post, put, patch, remove, upload };
