<template>
  <div class="spinner-box">
    <div class="d-flex justify-content-center w-100">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">
          {{ loadingTip }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    position: {
      type: String,
      required: true,
      default: 'fixed',
    },
    loadingTip: {
      type: String,
      required: true,
      default: 'Loading...',
    },
    color: {
      type: String,
      required: true,
      default: 'black',
    },
  },
};
</script>

<style scoped>
.spinner-box {
  position: v-bind(position);
  color: v-bind(color);
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  text-align: center;
  opacity: 0.3;
  background-color: #efefef;
}

.spinner-border {
  position: fixed;
  top: 50%;
}
</style>
