import ApiHelper from './index';

async function createErgonomicsRecord(payload, spinner = false) {
  return await ApiHelper.post(
    'websocket_inference/ergonomics_records/',
    payload,
    spinner
  );
}

async function getErgonomicsRecords(query = {}, spinner = true) {
  return await ApiHelper.get(
    'websocket_inference/ergonomics_records/',
    spinner,
    query
  );
}

async function deleteErgonomicsRecord(recordId, spinner = true, query = {}) {
  return await ApiHelper.remove(
    `websocket_inference/ergonomics_record/${recordId}/`,
    spinner,
    query
  );
}

export default {
  createErgonomicsRecord,
  getErgonomicsRecords,
  deleteErgonomicsRecord,
};
