import ApiHelper from './index';
import { getAuthConfig } from './config';

async function fetchAllDevicesOfOrg(orgName, spinner = true, queryParams = {}) {
  return await ApiHelper.get(
    `device/device_org/${orgName}/`,
    spinner,
    queryParams
  );
}

async function fetchAllDepartmentLessDevices(organization, spinner = true) {
  let [error, data] = await ApiHelper.get(
    'device/' + organization + '/get_department_less_devices/',
    spinner
  );
  if (data) data = data.filter(({ Type }) => Type !== 'departmental device');
  return [error, data];
}

async function assignDeviceToDepartment(
  organization,
  deviceID,
  payload = {},
  spinner = true
) {
  return await ApiHelper.patch(
    'device/' + organization + '/assign_device_to_department/' + deviceID + '/',
    payload,
    getAuthConfig(),
    spinner
  );
}

const getAllOrganizations = async (spinner = true) => {
  return await ApiHelper.get('organization/', spinner);
};

const getDeviceInfo = async (query, spinner = true) => {
  return await ApiHelper.get('device/devicelistadminview?' + query, spinner);
};

const updateDevice = async (serialNumber, payload, spinner = true) => {
  return await ApiHelper.patch(`device/${serialNumber}/`, payload, spinner);
};

const fetchDeviceSettings = async (spinner = true, queryParams = {}) => {
  return await ApiHelper.get('device/device_setting/', spinner, queryParams);
};

const getActiveDevicesOfOrg = async (spinner = false, queryParams = {}) => {
  return await ApiHelper.get(
    'device/get_active_devices/',
    spinner,
    queryParams
  );
};
// get multiple devices settings & status
const getSettingsOfActiveDevices = async (
  spinner = false,
  queryParams = {}
) => {
  return await ApiHelper.get(
    'device/multi_device_settings/',
    spinner,
    queryParams
  );
};

const getStatusOfActiveDevices = async (spinner = false, queryParams = {}) => {
  return await ApiHelper.get('device/device_status/', spinner, queryParams);
};

const updateDeviceSettings = async (
  organization,
  deviceSettingId,
  payload,
  spinner = true
) => {
  return await ApiHelper.patch(
    `device/${organization}/device_settings_update/${deviceSettingId}/`,
    payload,
    spinner
  );
};
const updateDeviceSetting = async (
  deviceId,
  organization,
  payload,
  spinner = true
) => {
  return await ApiHelper.patch(
    `device/${organization}/device_settings_update/${deviceId}/`,
    payload,
    spinner
  );
};
const getDevicesRunningSelectedTask = async (
  spinner = false,
  queryParams = {}
) => {
  return await ApiHelper.get(
    'organization/task/task_inference_status',
    spinner,
    queryParams
  );
};
async function fetchBlurRegions(id, spinner = true) {
  return await ApiHelper.get(
    `device/blur_regions?device_settings=${id}`,
    spinner
  );
}
const saveBlurRegions = async (payload, spinner = true) => {
  return await ApiHelper.post(`device/blur_regions`, payload, spinner);
};
const deleteBlurRegion = async (id, spinner = true) => {
  return await ApiHelper.remove(
    `device/delete_blur_regions?ids=${id}`,
    spinner
  );
};
const updateBlurRegions = async (payload, spinner = true) => {
  return await ApiHelper.put(`device/blur_regions`, payload, spinner);
};

const updateProjectorStatus = async (
  deviceId,
  organization,
  payload,
  spinner = true
) => {
  return await ApiHelper.patch(
    `device/${organization}/device_settings_update/${deviceId}/`,
    payload,
    spinner
  );
};

const getDeviceDetails = async (
  deviceSerialNumber,
  organization,
  spinner = true
) => {
  return await ApiHelper.get(
    `device/${deviceSerialNumber}/device_details/`,
    spinner
  );
};

const setFetchAnalytics = async (serialNumber, spinner = true) => {
  return await ApiHelper.post(
    `device/set_fetch_analytics/${serialNumber}/`,
    spinner
  );
};

const updateCurrentScreen = async (
  deviceId,
  organization,
  payload,
  spinner = true
) => {
  return await ApiHelper.patch(
    `device/${organization}/device_settings_update/${deviceId}/`,
    payload,
    spinner
  );
};
export default {
  fetchAllDevicesOfOrg,
  fetchAllDepartmentLessDevices,
  assignDeviceToDepartment,
  getAllOrganizations,
  getDeviceInfo,
  fetchDeviceSettings,
  updateDevice,
  getActiveDevicesOfOrg,
  getSettingsOfActiveDevices,
  getStatusOfActiveDevices,
  updateDeviceSettings,
  updateDeviceSetting,
  getDevicesRunningSelectedTask,
  fetchBlurRegions,
  saveBlurRegions,
  deleteBlurRegion,
  updateBlurRegions,
  updateProjectorStatus,
  getDeviceDetails,
  setFetchAnalytics,
  updateCurrentScreen,
};
