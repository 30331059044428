import types from '../mutation-types';
import VideosService from '../../services/videos';
import videoTypes from '../../config/video-type-config';
import { getQueryParams } from '../../utils/videos';

const state = {
  videoList: [],
  isFetchingVideos: false,
  videosCount: 0,
  videoTypes,
  selectedVideoType: videoTypes['All Videos'],
};

const getters = {
  videoList: (state) => state.videoList,
  isFetchingVideos: (state) => state.isFetchingVideos,
  videosCount: (state) => state.videosCount,
  videoTypes: (state) => state.videoTypes,
  selectedVideoType: (state) => state.selectedVideoType,
};

const actions = {
  async fetchVideos({ commit, dispatch }, { taskId, ...rest }) {
    commit(types.SET_IS_FETCHING_VIDEOS, true);
    const queryParams = getQueryParams(rest);
    const [error, data] = await VideosService.fetchVideos(
      taskId,
      false,
      queryParams
    );
    commit(types.SET_IS_FETCHING_VIDEOS, false);
    if (error) {
      console.log({ error });
      return;
    }
    const { results, count } = data;
    commit(types.SET_VIDEOS_LIST, results);
    commit(types.SET_VIDEOS_COUNT, count);
  },

  updateVideoItem({ commit, state }, video) {
    const temp = [...state.videoList];
    const itemIndex = temp.findIndex((v) => v.id === video.id);
    temp[itemIndex] = { ...video };
    commit(types.SET_VIDEOS_LIST, temp);
  },

  setVideosList({ commit }, payload) {
    commit(types.SET_VIDEOS_LIST, payload);
  },
  setVideosCount({ commit }, payload) {
    commit(types.SET_VIDEOS_COUNT, payload);
  },

  setSelectedVideoType({ commit }, payload) {
    commit(types.SET_SELECTED_VIDEO_TYPE, payload);
  },
};

const mutations = {
  [types.SET_VIDEOS_LIST](state, payload) {
    state.videoList = payload;
  },
  [types.SET_VIDEOS_COUNT](state, payload) {
    state.videosCount = payload;
  },
  [types.SET_IS_FETCHING_VIDEOS](state, payload) {
    state.isFetchingVideos = payload;
  },
  [types.SET_SELECTED_VIDEO_TYPE](state, payload) {
    state.selectedVideoType = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
