import store from 'src/store';

const getAuth = () => {
  const token = localStorage.getItem('token');
  return 'Bearer ' + token;
};

export const getAuthConfig = () => {
  const header = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: getAuth(),
    SessionKey: localStorage.getItem('sessionKey'),
  };
  return { headers: header };
};

export const getUploadConfig = (onUploadProgress) => {
  const header = {
    'Content-Type': 'application/json',
    Accept: '*/*',
    Authorization: getAuth(),
  };
  return {
    headers: header,
    onUploadProgress,
  };
};

export const getConfig = () => {
  const header = { 'Content-Type': 'application/json', Accept: '*/*' };
  return { headers: header };
};
