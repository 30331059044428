export const compareTwoArrays = (arr1, arr2) => {
  const added = arr2.filter(
    (obj2) => !arr1.some((obj1) => obj1.id === obj2.id)
  );
  const removed = arr1.filter(
    (obj1) => !arr2.some((obj2) => obj2.id === obj1.id)
  );
  const modified = arr1.filter((obj1) => {
    const obj2 = arr2.find((obj) => obj.id === obj1.id);
    if (obj2) {
      return Object.keys(obj1).some((key) => obj1[key] !== obj2[key]);
    }
    return false;
  });
  return { added, removed, modified };
};
