export default {
  currentRoute(state) {
    return state.currentRoute;
  },
  routerConfig(state) {
    return state.routeDetail;
  },
  getTaskId(state) {
    return state.taskId;
  },
  getSidebarState(state) {
    return state.sidebar;
  },
  getTheme(state) {
    return state.theme;
  },
  currentObject(state) {
    return state.currentObject;
  },
  isTourRunning(state) {
    return state.isTourRunning;
  },
  prevMetaData(state) {
    return state.prevMetaData;
  },
  totalFrames(state) {
    return state.totalFrames;
  },
  // frameStepSize(state) {
  //   return state.frameStepSize;
  // },
};
