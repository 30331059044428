export default {
  mounted(el, binding) {
    const onClick = (e) => {
      if (!el.contains(e.target)) {
        binding.value();
      }
    };
    document.addEventListener('click', onClick);
    el._clickOutside = onClick;
  },
  beforeUnmount(el) {
    document.removeEventListener('click', el._clickOutside);
  },
};
