import { chartTabs } from '@/config/joints-chart-config.js';
import { owasScoreTabs } from 'src/components/user-panel/pages/ergonomics/config';
import { getIntervalTime } from 'src/components/shared/Helpers/getTime';

export const getDataPartitions = (data, frameps) => {
  const fps = Number(frameps);
  const part = Math.ceil(fps / 10);
  let partitions = [];
  data = data.slice(0, data.length - 1);

  let nextSecondEndFrame = fps;
  let end = part;
  let intervalEnd = 0;
  for (let i = 0; i < data.length; i += end) {
    if (i + part < nextSecondEndFrame) {
      intervalEnd = i + part;
      if (end !== part) end = part;
    } else {
      intervalEnd = intervalEnd + (nextSecondEndFrame - i);
      end = nextSecondEndFrame - i;
      nextSecondEndFrame = nextSecondEndFrame + fps;
    }
    const chunk = data.slice(i, intervalEnd);
    partitions.push(chunk);
  }
  return partitions;
};

export const getChartData = (data, frameps) => {
  let yAngles = {};
  let xLabels = [];

  const partitions = getDataPartitions(data, frameps);
  let frame = 0;
  partitions.forEach((framesDataPerInterval) => {
    frame += framesDataPerInterval.length;
    chartTabs.forEach((joint) => {
      const yAttribute = joint.key;
      const newYAngle = getYAnglesPerInterval(
        framesDataPerInterval,
        yAttribute,
        frame
      );
      yAngles[yAttribute] = yAngles[yAttribute]
        ? [...yAngles[yAttribute], newYAngle]
        : [newYAngle];
    });
  });

  xLabels = Array.from(new Array(partitions.length), (_, i) => i + 1);
  return { yAngles, xLabels };
};

const getYAnglesPerInterval = (framesPerInterval, yAttribute, frameNo) => {
  if (
    framesPerInterval.length &&
    framesPerInterval[0][yAttribute] &&
    Array.isArray(framesPerInterval[0][yAttribute]) //trunk Angle array
  ) {
    return {
      frame: frameNo,
      y: framesPerInterval[framesPerInterval.length - 1][yAttribute][0],
    };
  } else {
    return {
      frame: frameNo,
      y: framesPerInterval[framesPerInterval.length - 1][yAttribute],
    };
  }
};

export const getOwasChartData = (data, frameps) => {
  let yAngles = {};
  let xLabels = [];

  const partitions = getDataPartitions(data, frameps);
  let frame = 0;
  partitions.forEach((framesDataPerInterval, x) => {
    frame += framesDataPerInterval.length;
    owasScoreTabs.forEach((joint) => {
      const yAttribute = joint.key;
      const limbName = joint.tab;
      const newYAngle = getHeatMapCellData(
        framesDataPerInterval,
        yAttribute,
        frame,
        limbName,
        x
      );
      yAngles[yAttribute] = yAngles[yAttribute]
        ? [...yAngles[yAttribute], newYAngle]
        : [newYAngle];
    });
  });
  xLabels = Array.from(new Array(partitions.length), (_, i) => i + 1);
  return { yAngles, xLabels };
};

export const getHeatMapCellData = (
  interval,
  limb_attribute,
  frame,
  limbName,
  x
) => {
  if (interval.length && interval[0][limb_attribute]) {
    const value = interval[interval.length - 1][limb_attribute];
    const color = getScoreLimbSeverity(value, limbName, 'owas', true);
    return {
      x: x,
      y: value,
      value: value,
      color: color,
      frame: frame,
      limbName: limbName,
    };
  }
};

const limbsData = {
  'Right Upper Arm': {
    key: 'right_upper_arm',
    scoreAttribute: 'right_upper_arm_score',
    angleAttribute: 'right_upper_arm_angle',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 3,
    scores: ['reba', 'rula'],
  },
  'Left Upper Arm': {
    key: 'left_upper_arm',
    scoreAttribute: 'left_upper_arm_score',
    angleAttribute: 'left_upper_arm_angle',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 3,
    scores: ['reba', 'rula'],
  },
  'Right Lower Arm': {
    key: 'right_lower_arm',
    scoreAttribute: 'right_lower_arm_score',
    angleAttribute: 'right_lower_arm_angle',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 2: 'Low', 3: 'Medium', 4: 'High' },
    threshold: 2,
    scores: ['rula'],
  },
  'Left Lower Arm': {
    key: 'left_lower_arm',
    scoreAttribute: 'left_lower_arm_score',
    angleAttribute: 'left_lower_arm_angle',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 2: 'Low', 3: 'Medium', 4: 'High' },
    threshold: 2,
    scores: ['rula'],
  },
  'Right Wrist': {
    key: 'right_wrist',
    scoreAttribute: 'right_wrist_score',
    angleAttribute: 'right_wrist_angle',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba', 'rula'],
  },
  'Left Wrist': {
    key: 'left_wrist',
    scoreAttribute: 'left_wrist_score',
    angleAttribute: 'left_wrist_angle',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba', 'rula'],
  },
  Neck: {
    key: 'neck',
    scoreAttribute: 'final_neck_score',
    angleAttribute: 'neck_angle',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba', 'rula'],
  },
  Trunk: {
    key: 'trunk',
    scoreAttribute: 'final_trunk_score',
    angleAttribute: 'trunk_bending_angles',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 3,
    scores: ['reba', 'rula'],
  },
  'Right Knee': {
    key: 'right_leg',
    scoreAttribute: 'right_leg_score',
    angleAttribute: 'right_knee_angle',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba'],
  },
  'Left Knee': {
    key: 'left_leg',
    scoreAttribute: 'left_leg_score',
    angleAttribute: 'left_knee_angle',
    rebaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    rulaScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['reba'],
  },
};

const OwasLimbsData = {
  Trunk: {
    key: 'trunk',
    scoreAttribute: 'final_trunk_score',
    angleAttribute: '',
    owasScoreThresholds: { 2: 'Low', 3: 'Medium', 4: 'High' },
    threshold: 3,
    scores: ['owas'],
  },
  Arms: {
    key: 'arm',
    scoreAttribute: 'final_arm_score',
    angleAttribute: '',
    owasScoreThresholds: { 1: 'Low', 2: 'Medium', 3: 'High' },
    threshold: 2,
    scores: ['owas'],
  },
  Legs: {
    key: 'leg',
    scoreAttribute: 'final_leg_score',
    angleAttribute: '',
    owasScoreThresholds: { 3: 'Low', 5: 'Medium', 7: 'High' },
    threshold: 2,
    scores: ['owas'],
  },
};

export const riskColorsMap = {
  Low: '#0A8856',
  Medium: '#1890ff', //#0d6efd
  High: '#E34234',
};

export const getREBAScoreSeverety = (score) => {
  switch (true) {
    case score <= 1:
      return 'Acceptable';
    case score == 2 || score == 3:
      return 'Low';
    case score >= 4 && score <= 7:
      return 'Medium';
    case score >= 8 && score <= 10:
      return 'High';
    case score >= 11:
      return 'Very High';
    default:
      return '';
  }
};

export const getRULAScoreSeverety = (score) => {
  switch (true) {
    case score <= 2:
      return 'Low';
    case score >= 3 && score <= 4:
      return 'Medium';
    case score >= 5:
      return 'High';
    default:
      return '';
  }
};

export const getOWASScoreSeverity = (score) => {
  switch (true) {
    case score <= 2:
      return 'Low';
    case score == 3:
      return 'Medium';
    case score >= 4:
      return 'High';
    default:
      return '';
  }
};

export function getScoreLimbSeverity(
  scoreValue,
  limbName,
  scoreType = 'reba',
  forColor = false
) {
  const scoreThresholdKey = scoreType + 'ScoreThresholds';
  let limbScoreDict = scoreType === 'owas' ? OwasLimbsData : limbsData;
  const thresholdKeys = Object.keys(limbScoreDict[limbName][scoreThresholdKey]);
  const threshold = thresholdKeys?.find(
    (threshold) => scoreValue <= Number(threshold)
  );
  var status = '';
  if (threshold) status = limbScoreDict[limbName][scoreThresholdKey][threshold];
  else status = 'High';

  return forColor ? riskColorsMap[status] : status;
}

// export const getRiskSeverety = (score, forColor = false) => {
//   switch (true) {
//     case score == 1:
//       return forColor ? riskColorsMap['Low'] : 'Low';
//     case score == 2:
//       return forColor ? riskColorsMap['Medium'] : 'Medium';
//     case score >= 3:
//       return forColor ? riskColorsMap['High'] : 'High';
//     default:
//       return '';
//   }
// };

const scoreKeys = {
  reba: 'final_reba_score',
  rula: 'final_rula_score',
  owas: 'final_owas_score',
};

export const getPostureFrameData = (frameData, scoreType = 'reba') => {
  let temp = {
    riskOfInjury: '',
    postureRisks: [],
  };
  if (!frameData) return temp;
  temp['riskOfInjury'] = frameData[scoreKeys[scoreType]];
  let scoreLimbs;
  if (scoreType === 'owas') {
    scoreLimbs = Object.entries(OwasLimbsData);
  } else {
    scoreLimbs = Object.entries(limbsData).filter(([_, limbData]) =>
      limbData.scores.includes(scoreType)
    );
  }

  temp['postureRisks'] = getLimbScoreFrameData(
    scoreLimbs,
    frameData,
    scoreType
  );
  return temp;
};

const getLimbScoreFrameData = (scoreLimbs, frameData, scoreType) => {
  let postureData = [];

  scoreLimbs.forEach(([name, limbData]) => {
    const { key, angleAttribute, threshold, scoreAttribute } = limbData;
    const scoreThresholdKey = scoreType + 'ScoreThresholds';
    const lowThreshold = Object.keys(limbData[scoreThresholdKey])[0];
    const limbScore = frameData[scoreAttribute];
    let obj = {};

    if (limbScore > Number(lowThreshold) && limbScore >= threshold) {
      const bofData = frameData['benefits_of_fixing'];
      obj['limbName'] = name;
      obj['score'] = limbScore;
      obj['reduction'] = bofData[key]?.length ? bofData[key][0] : '';
      obj['risk_after_fixing'] = bofData[key]?.length ? bofData[key][1] : '';
      if (angleAttribute) {
        obj['angles'] = Array.isArray(frameData[angleAttribute])
          ? Math.max(...frameData[angleAttribute])
          : frameData[angleAttribute];
      }
      postureData.push(obj);
    }
  });

  return postureData;
};

// export const getPostureData = (frameData) => {
//   let temp = {
//     riskOfInjury: '',
//     risks: [],
//   };
//   let postureData = [];

//   temp['riskOfInjury'] = frameData.final_reba_score;
//   Object.entries(limbsData).forEach(([name, limbData]) => {
//     const { threshold, scoreAttribute } = limbData;
//     let obj = {};
//     if (frameData[scoreAttribute] >= threshold) {
//       obj['score'] = frameData[scoreAttribute];
//       obj['title'] = name;
//       postureData.push(obj);
//     }
//   });
//   temp['risks'] = postureData;
//   return temp;
// };

// export const getInvestigateData = (frameData, bofData, scoreType = 'reba') => {
//   let investigateData = [];

//   Object.entries(limbsData).forEach(([name, limbData]) => {
//     const { key, angleAttribute, threshold, scoreAttribute, scores } = limbData;
//     let obj = {};
//     if (frameData[scoreAttribute] >= threshold) {
//       obj['issueTitle'] = name;
//       obj['name'] = name;
//       obj['angles'] = Array.isArray(frameData[angleAttribute])
//         ? Math.max(...frameData[angleAttribute])
//         : frameData[angleAttribute];
//       obj['score'] = frameData[scoreAttribute];
//       obj['reduction'] = bofData[key]?.length ? bofData[key][0] : '';
//       obj['risk_after_fixing'] = bofData[key]?.length ? bofData[key][1] : '';
//       investigateData.push(obj);
//     }
//   });
//   return investigateData;
// };

export const getHighRisksIntervalData = (data) => {
  if (!data) return;
  const { high_risk_interval_frame_list, total_highest_score } = data;
  return {
    intervals: high_risk_interval_frame_list
      ? high_risk_interval_frame_list
      : [],
    highes_score: total_highest_score,
  };
};

export const getIntervalVideoTime = (fps, interval) => {
  // start & end frame of interval
  const iStart = interval[0];
  const iEnd = interval[interval.length - 1];

  let time = '';
  // calculate second a/c to fps
  const start = iStart / fps;
  const end = iEnd / fps;

  const formattedStart = getIntervalTime(start * 1000);
  const formattedEnd = getIntervalTime(end * 1000);
  // time = start >= 60 ? formattedStart : formattedStart;

  // if interval is greater than equal to 1 second
  if (iEnd - iStart < fps) {
    time = formattedStart;
  } else if (iEnd - iStart >= fps) {
    time = `${formattedStart} - ${formattedEnd}`;
  }
  return time;
};

export const getCreateRecordPayload = (file) => {
  return {
    organization: localStorage.getItem('organization'),
    thumbnail_path: file['video_thumbnail'],
    coupling_score: Number(file['couplingScore']),
    load_score: Number(file['loadScore']),
    action_score: Number(file['adjustmentScore']),
    file_name: file['video_thumbnail']
      .split('Videos/')
      .pop()
      .replace('/', ''),
    start_time: new Date().toISOString(),
  };
};

export const getLoadActivityFrequency = (jobPercent) => {
  jobPercent = Number(jobPercent);
  if (jobPercent < 1) return 'Never';
  else if (jobPercent >= 1 && jobPercent <= 31) return 'Ocassionally';
  else if (jobPercent >= 34 && jobPercent <= 66) return 'Frequently';
  else if (jobPercent >= 67 && jobPercent <= 100) return 'Constantly';
};
