import ApiHelper from './index';

const getModelVersionList = async (queryParams, spinner = true) => {
  return await ApiHelper.get(
    'organization/model_version',
    spinner,
    queryParams
  );
};

const createModelVersion = async (payload, spinner = false) => {
  return await ApiHelper.post('organization/model_version', payload, spinner);
};

const deleteModelVersion = async (
  modelVersionId,
  spinner = false,
  deleteFlag
) => {
  return await ApiHelper.patch(
    `organization/model_version/${modelVersionId}`,
    { is_deleted: deleteFlag },
    spinner
  );
};

const updateModelVersion = async (modelVersionId, payload, spinner = true) => {
  return await ApiHelper.patch(
    `organization/model_version/${modelVersionId}`,
    payload,
    spinner
  );
};

const fetchModelTypes = async (spinner = true) => {
  return await ApiHelper.get('organization/task/model_choices', spinner);
};

export default {
  getModelVersionList,
  createModelVersion,
  updateModelVersion,
  deleteModelVersion,
  fetchModelTypes,
};
