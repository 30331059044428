export const vapidKey =
  'BAAheBsasgT1tG0tSNenh7JCNkDzTUriAyp7Sf4I_UfzRNJhCOROary86eUwyWBaFr8v5IaF-HjbCee3IJ1qtPE';

export const validRoutesName = [
  'Record Training Set',
  'Deploy Model',
  'Devices',
  'OPCUA Explorer',
  'Define Task',
];
