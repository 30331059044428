import apiHelper from "./index"

async function getAllDepartmentsOfOrg(orgName, spinner=true){
    return await apiHelper.get('organization/' + orgName + '/department/', spinner)
}

async function createDepartment(orgName, departName, spinner=true){
    return await apiHelper.post('organization/' + orgName + '/department/', {name: departName})
}

async function switchOrganization(payload) {
    return await apiHelper.patch(
      `organization/organization-switch`,
      payload,
      false
    );
}

async function createKaizenBucket(payload){
  return await apiHelper.post('organization/create_kaizen_bucket' , payload)
}




export default {getAllDepartmentsOfOrg, createDepartment, switchOrganization, createKaizenBucket}
