const requireModule = require.context('.', false, /\.svg$/); // Get svg files inside modules folder

const modules = {};

requireModule.keys().forEach((filePath) => {
  if (filePath === './index.js') return;

  const fileName = filePath
    .replace('.svg', '')
    .replace('./', '')
    .split('-')
    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join('');

  modules[`${fileName}`] = requireModule(filePath).default;
});

export default modules;
