import axios from 'axios';

// const URL =
//   'https://5vzjp9dxua.execute-api.us-east-2.amazonaws.com/prod/firebase_topic_subscription';

let config = {
  headers: {
    'Content-Type': 'application\\json',
    // "Access-Control-Allow-Origin" : "*",
    // "Accept": "*/*",
    // "Connection" : "keep-alive",
    // "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT"
  },
};

const URL =
  'https://637chban56fnlgl4hw6mlvv2740gsmuj.lambda-url.us-east-2.on.aws/';

const subscribeToChannel = async (payload) => {
  try {
    console.log('SUBSCRIBE TO FIREBASE');
    payload.route = '/firebase_topic_subscription';
    const { data } = await axios.post(
      'https://xwhs7jwvl3.execute-api.us-east-2.amazonaws.com/Demo/firebase_topic_subscription',
      payload,
      config
    );
    return [null, data];
  } catch (error) {
    return [error];
  }
};

const unsubscribeToChannel = async (payload) => {
  try {
    const { data } = await axios.delete(URL, payload);
    return [null, data];
  } catch (error) {
    return [error];
  }
};

const sendMessageToRabbitMQ = async (payload) => {
  try {
    payload.route = '/send_message_to_lambda';
    const Lamba_URL =
      'https://xwhs7jwvl3.execute-api.us-east-2.amazonaws.com/Demo/send_message_to_lambda';
    const { data } = await axios.post(Lamba_URL, payload, config);
    return [null, data];
  } catch (error) {
    return [error];
  }
};

export default {
  subscribeToChannel,
  unsubscribeToChannel,
  sendMessageToRabbitMQ,
};
