import { axiosInstance } from 'src/services/index';
import store from '../../store/index';
import { useToast } from 'vue-toastification';
import * as configuration from './httpRequestConfig';

export function login(
  baseUrl,
  data,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage,
  errorMessage
) {
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }
  return axios
    .post(hostUrl + baseUrl, data, config)
    .then((response) => response.data)
    .then((response) => {
      store.dispatch('turnOffSpinner');
      if (log) {
        // console.log("response");
        // console.log(response);
      }
      return response;
    })
    .catch((error) => {
      if (alert) {
        useToast().error(error.response.data.non_field_errors[0]);
      }
      store.dispatch('turnOffSpinner');
      return 'error';
    });
}

export function register(
  baseUrl,
  data,
  hostUrl,
  config,
  spinner,
  log,
  alert,
  successMessage,
  errorMessage
) {
  if (spinner) {
    store.dispatch('turnOnSpinner');
  }

  return axios
    .post(hostUrl + baseUrl, data, config)
    .then((response) => response.data)
    .then((response) => {
      if (alert) {
        useToast().success(successMessage);
      }
      // "Form Submitted!"

      store.dispatch('turnOffSpinner');

      if (log) {
        // console.log("response");
        // console.log(response);
      }
      return response;
    })
    .catch((error) => {
      if (alert) {
        if (error.response) {
          useToast().error(error.response.data.email[0]);
        } else if (error.request) {
          useToast().error(error.request);
        } else {
          useToast().error(error.message);
        }
      }
      store.dispatch('turnOffSpinner');
      return error;
    });
}
