import httpClient from '../../service/httpClient';
import types from '../mutation-types';

const state = {
  roles: [],
};

const getters = {
  roles: (state) => state.roles,
};

const actions = {
  async addNewRole({ commit, dispatch }, payload) {
    const res = await httpClient.post(
      'account/roles/',
      { name: payload },
      true,
      false,
      true,
      'Role added successfully',
      'Role could not be added'
    );
    commit(types.ADD_ROLE, res);
    dispatch('getAllRoles');
  },

  async getAllRoles({ commit }) {
    const roles = await httpClient.get('account/roles/');
    if (roles.length >= 1) {
      commit(types.GET_ROLES, roles);
    }
  },
};

const mutations = {
  [types.ADD_ROLE](state, payload) {
    state.roles = [...state.roles, { ...payload }];
  },
  [types.GET_ROLES](state, payload) {
    state.roles = [...payload];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
