import { roles } from 'src/config/roles-config.js';
import types from 'src/store/mutation-types';

export const setUserDetails = async (data, commit) => {
  const {
    id,
    name,
    email,
    role,
    token,
    Organization,
    poc,
    is_policy_accepted,
    is_otp_verified,
    require_otp,
    refresh,
    session_key,
  } = data;
  const username = role === roles.admin ? 'Andrey Konin' : name;
  const org = role === roles.admin ? 'ADMINISTRATOR' : Organization;

  const currentTime = new Date();
  window.localStorage.setItem('id', id);
  window.localStorage.setItem('name', username);
  window.localStorage.setItem('email', email);
  window.localStorage.setItem('role', role);
  window.localStorage.setItem('organization', org);
  window.localStorage.setItem('token', token);
  window.localStorage.setItem('refresh', refresh);
  window.localStorage.setItem('timestamp', currentTime);
  window.localStorage.setItem('poc', poc);
  window.localStorage.setItem('isPolicyAccepted', is_policy_accepted);

  window.localStorage.setItem('require_otp', false);
  window.localStorage.setItem('isOtpSetup', is_otp_verified);
  window.localStorage.setItem('sessionKey', session_key);

  commit(types.SAVE_USER, username);
  commit(types.SAVE_USER_EMAIL, email);
  commit(types.SAVE_TOKEN, token);
  commit(types.SAVE_REFRESH_TOKEN, refresh);
  commit(types.SAVE_ORGANIZATION, org);
  commit(types.SAVE_ROLE, role);
  commit(types.SET_POC_FLAG, poc);
  commit(types.SET_POLICY_FLAG, is_policy_accepted);
  commit(types.SET_OTP_FLAG, is_otp_verified);
  commit(types.SET_REQUIRE_OTP, false);
};

export const setOTPVerificationStatus = async (commit) => {
  window.localStorage.setItem('isOtpSetup', true);
  window.localStorage.setItem('require_otp', false);
  commit(types.SET_REQUIRE_OTP, false);
  commit(types.SET_OTP_FLAG, true);
};

export const setOTPStatus = async (commit) => {
  window.localStorage.setItem('isOtpSetup', true);
  commit(types.SET_OTP_FLAG, true);
};

export const setEmailForResendVerification = async (email, commit) => {
  commit(types.SET_EMAIL, email);
};

export const setAzureToken = async (token, commit) => {
  window.localStorage.setItem('azureLogin', true);
  commit(types.SET_AZURE_LOGIN, true);
};

export const removeAzureToken = async (commit) => {
  window.localStorage.removeItem('azureLogin');
  commit(types.SET_AZURE_LOGIN, false);
};

export function getAzureLoginStatus() {
  return window.localStorage.getItem('azureLogin');
}

export const isUserAssignedToOrg = async (data) => {
  const { is_admin, Organization, role } = data;
  return !Organization && !is_admin && role !== roles.labeler;
};

export const validUserName = async (_, name) => {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return Promise.resolve();
  }
  const fieldName = _.field.charAt(0).toUpperCase() + _.field.slice(1);
  if (name === '') return Promise.reject(`${fieldName} is required!`);
  return Promise.reject(
    'Invalid Name. Valid name only contain letters, dashes (-) and spaces'
  );
};
